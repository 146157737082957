import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommonServiceService } from '../services/common-service.service';
import { EditSchoolComponent } from '../../studio/school/edit-school/edit-school.component';
import { AddEditSchoolComponent } from '../../studio/school/add-edit-school/add-edit-school.component';
import { ImageContentComponent } from '../../studio/albums/image-content/image-content.component';
import { HomeComponent } from '../../studio/event/home/home.component';
import { EventDetailsComponent } from '../../studio/event/event-details/event-details.component';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  userData;
  currentStatus = "";
  authState: any;
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private commonFun: CommonServiceService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.afAuth.authState.pipe(
      take(1),
      switchMap((user: any) => {
        // this.commonFun.getLoginUserData();
          if (state.url === '/auth' && user && !user?.isAnonymous) {
            // If the user is already logged in and tries to access the login page, redirect to dashboard
            return this.router.navigate(['/dashboard']).then(() => false);
          } else if((state.url).includes('/auth/tokenBasedSignIn') && !user){
            return of(true);
          } else if (state.url !== '/auth' && (!user || user?.isAnonymous)) {
            // If the user is not logged in and tries to access a route other than login, redirect to login
            return this.router.navigate(['/auth']).then(() => false);
          } else {
            return of(true);
          }
      })
    );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      return this.afAuth.authState.pipe(
        take(1),
        switchMap((user: any) => {
          // this.commonFun.getLoginUserData();
          if (state.url === 'auth' && (user && !user?.isAnonymous)) {
            // If the user is already logged in and tries to access the login page, redirect to dashboard
            return this.router.navigate(['/dashboard']).then(() => false);
          } else if (state.url !== '/auth' && (!user || user?.isAnonymous)) {
            // If the user is not logged in and tries to access a route other than login, redirect to login
            return this.router.navigate(['/auth']).then(() => false);
          } else {
            if(!user || user?.isAnonymous){
              return this.router.navigate(['/auth']).then(() => false);
            } else return of(true)
          }
        })
      );
  }

  canDeactivate(component: ImageContentComponent | EditSchoolComponent | AddEditSchoolComponent | HomeComponent |  EventDetailsComponent) {
    if (component && JSON.stringify(component.pageData) != JSON.stringify(component.comparePageData)) {
      return component.openConfirmationDialog(
        'Attention',
        'You have unsaved changes. What do you want to do?',
        'Forget changes',
        'Continue editing',
        false,
        true,
        true
      ).then((res: any) => {
        if (res) {
          return true;
        } else {
          if (component instanceof EditSchoolComponent || component instanceof ImageContentComponent) {
            this.commonFun.goBackButton$.next({ isShow: true });
          }
          return false;
        }
      }).catch((err) => {
        console.log('err: guard', err);
        return false;
      })
    } else {
      return true;
    }
  }

}
