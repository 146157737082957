import { Component, OnInit } from '@angular/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { EventService } from '../../services/event/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [
    ProgressBarModule
  ],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent implements OnInit {

  loaderVal: number = 0;
  imageData: any;
  loadingCountSubscription: Subscription
  
  constructor(
    private eventService: EventService
  ) { }

  ngOnInit() {
    this.loadingCountSubscription = this.eventService.loaderUploadingCount$.subscribe((imageData: any) => {
      if (imageData) {
        this.imageData = imageData
        this.loaderVal =  Math.floor((imageData.successCount / imageData.totalImage) * 100);
      }
    })
  }

  ngOnDestroy(){
    this.loadingCountSubscription.unsubscribe()
  }
}
