<div class="image-upload-dialog-body">
    <!-- <div class="text-center event-heading">
        <h4>Event:- <span>Graduation</span></h4>
    </div>
    <div class="text-center event-heading">
        <h4>Album:- <span>Portraits</span></h4>
    </div> -->

    <div class="mt-4 justify-content-center d-flex gap-4">
        <div (click)="imageInput.click()" class="image-doc-uploader">
            <div (dragover)="onDragOver($event)" (drop)="onDrop($event, 'images')">
                <input style="display: none;" class="image-upload-input" #imageInput type="file" multiple 
                 (change)="onFileSelected($event, 'images')">
                <div class="drop-container">
                    <img src="../../../assets/svg/image-upload-icon.svg" alt="">
                    <label for="fileInput" class="drop-label">Drop Files Here</label>
                </div>
                <div class="text-center text-pre-wrap" *ngIf="!!imageFiles && imageFiles?.length > 0">
                {{ imageFiles.length > 1 ? imageFiles.length : '' }} {{ imageFiles.length == 1 ? imageFiles[0].name : " images selected" }}
              </div>
            </div>
        </div>
        <div class="d-flex flex-col gap-3 max-w-250px w-full">
           <p-button class="cmn-btn-primary w-full rounded-full" (click)="downloadSampleFile()">Get Template Data</p-button>
           <div (click)="csvInput.click()" class="image-doc-uploader" (drop)="onDrop($event, 'csv')" (dragover)="onDragOver($event)" >
               <div>
                   <input style="display: none;" class="image-upload-input" #csvInput type="file" accept=".csv,.txt"
                   (change)="onFileSelected($event, 'csv')">
                   <div class="drop-container" *ngIf="!csvFile">
                       <label for="fileInput" class="drop-label">Drop Csv Here</label>
                   </div>
                   <div class="text-center text-pre-wrap" *ngIf="csvFile">
                       {{csvFile.name}}
                     </div>
                 </div>
           </div>
           <!-- <p-checkbox class="d-flex justify-content-center" [(ngModel)]="useForID" label="Use For ID" value="UseForID"/> -->
           <span class="p-float-label cus-float-label max-w-250px w-full">
            <p-dropdown [disabled]="isAlbumTypeDisable" [(ngModel)]="selectedAlbumType" [options]="albumTypes" optionLabel="label" appendTo="body" optionValue="value" placeholder="Select District"></p-dropdown>
            <label for="Source-float-label">Album Type</label>
        </span>
        </div>
        <!-- <div (click)="csvInput.click()" class="image-doc-uploader">
            <div (dragover)="onDragOver($event)" (drop)="onDrop($event, 'csv')">
                <input style="display: none;" class="image-upload-input" #csvInput type="file" multiple
                accept=".csv, .txt" (change)="onFileSelected($event, 'csv')">
                <div class="drop-container">
                    <img src="../../../assets/svg/csv-icon.svg" alt="">
                    <label for="fileInput" class="drop-label">Drop CSV Here</label>
                </div>
                    <p>{{ csvFile?.name }}</p>
            </div>
        </div> -->
    </div>

    <div class="d-flex gap-4 mt-4">
        <p-button (click)="closeModel(true)" class="cmn-btn-secondary w-full rounded-full" label="Cancel" [outlined]="true"></p-button>
        <p-button (click)="closeModel(false)" class="cmn-btn-primary w-full rounded-full" label="Upload"></p-button>
    </div>
</div>